// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';
import maskSensitiveInfo from './utils';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Variáveis de ambiente do Supabase não configuradas');
}

let supabaseInstance: ReturnType<typeof createClient<Database>> | null = null;

// Configuração do cliente com tratamento de erros melhorado
const supabase = (() => {
  if (supabaseInstance) return supabaseInstance;
  
  supabaseInstance = createClient<Database>(supabaseUrl, supabaseAnonKey, {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: true,
      storage: localStorage,
      storageKey: 'sb-' + supabaseUrl.split('//')[1] + '-auth-token',
      flowType: 'pkce'
    },
    global: {
      headers: {
        'X-Client-Info': 'online-draughts-hub'
      }
    },
    realtime: {
      params: {
        eventsPerSecond: 10
      }
    }
  });

  return supabaseInstance;
})();

// Função auxiliar para obter a URL de redirecionamento
function getRedirectUrl(): string {
  return window.location.hostname === 'localhost'
    ? 'http://localhost:3001/auth/callback'
    : `${window.location.origin}/auth/callback`;
}

export { supabase, getRedirectUrl };
export { maskSensitiveInfo };
export default supabase;