class GameSounds {
  private audioContext: AudioContext | null = null;
  private enabled: boolean = true;

  private initializeAudioContext() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    } else if (this.audioContext.state === 'suspended') {
      this.audioContext.resume();
    }
  }

  private createOscillator(frequency: number, duration: number, type: OscillatorType = 'sine'): AudioBuffer | null {
    if (!this.audioContext) return null;
    
    const sampleRate = this.audioContext.sampleRate;
    const buffer = this.audioContext.createBuffer(1, sampleRate * duration, sampleRate);
    const channel = buffer.getChannelData(0);
    
    for (let i = 0; i < buffer.length; i++) {
      const t = i / sampleRate;
      channel[i] = Math.sin(2 * Math.PI * frequency * t) * 
                   Math.exp(-3 * t);
    }
    
    return buffer;
  }

  private playBuffer(buffer: AudioBuffer | null, volume: number = 0.1) {
    if (!this.enabled || !buffer || !this.audioContext) return;

    const source = this.audioContext.createBufferSource();
    const gainNode = this.audioContext.createGain();
    
    source.buffer = buffer;
    source.connect(gainNode);
    gainNode.connect(this.audioContext.destination);
    
    gainNode.gain.value = volume;
    
    source.start();
  }

  play(type: 'move' | 'capture' | 'promotion' | 'error' | 'victory' | 'defeat') {
    if (!this.enabled) return;
    
    this.initializeAudioContext();
    if (!this.audioContext) return;

    switch (type) {
      case 'move':
        const moveBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 0.1, this.audioContext.sampleRate);
        const moveChannel = moveBuffer.getChannelData(0);
        
        for (let i = 0; i < moveBuffer.length; i++) {
          const t = i / this.audioContext.sampleRate;
          moveChannel[i] = Math.sin(2 * Math.PI * 300 * t) * Math.exp(-10 * t);
        }
        
        this.playBuffer(moveBuffer, 0.1);
        break;

      case 'capture':
        // Som mais impactante tipo "clack" para captura
        const captureBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 0.15, this.audioContext.sampleRate);
        const captureChannel = captureBuffer.getChannelData(0);
        
        for (let i = 0; i < captureBuffer.length; i++) {
          const t = i / this.audioContext.sampleRate;
          // Som tipo "madeira batendo"
          const impact = Math.sin(2 * Math.PI * 100 * t) * Math.exp(-30 * t) + // Base grave
                        Math.sin(2 * Math.PI * 1200 * t) * Math.exp(-50 * t) + // Impacto agudo
                        Math.sin(2 * Math.PI * 600 * t) * Math.exp(-40 * t);   // Médio
          
          captureChannel[i] = impact;
        }
        
        this.playBuffer(captureBuffer, 0.2);
        break;

      case 'promotion':
        // Som de promoção (fanfarra curta e triunfante)
        const promotionBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 0.6, this.audioContext.sampleRate);
        const promotionChannel = promotionBuffer.getChannelData(0);
        
        // Sequência de notas ascendentes para celebração
        const promotionNotes = [
          440,  // A4
          554,  // C#5
          659,  // E5
          880   // A5
        ];
        
        for (let i = 0; i < promotionBuffer.length; i++) {
          const t = i / this.audioContext.sampleRate;
          const noteIndex = Math.floor(t * 8) % promotionNotes.length;
          const freq = promotionNotes[noteIndex];
          
          // Som mais limpo e brilhante
          let sound = 
            Math.sin(2 * Math.PI * freq * t) * 0.5 +        // Frequência fundamental
            Math.sin(4 * Math.PI * freq * t) * 0.25 +       // Primeiro harmônico
            Math.sin(6 * Math.PI * freq * t) * 0.125;       // Segundo harmônico
          
          // Envelope mais suave
          const envelope = Math.min(t * 10, 1) * Math.exp(-3 * t);
          
          // Adiciona um leve brilho
          const shimmer = Math.sin(2 * Math.PI * 1200 * t) * 0.1 * envelope;
          
          promotionChannel[i] = (sound + shimmer) * envelope * 0.5;
        }
        
        this.playBuffer(promotionBuffer, 0.2);
        break;

      case 'victory':
        // Fanfarra real de vitória
        const victoryBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 2.0, this.audioContext.sampleRate);
        const victoryChannel = victoryBuffer.getChannelData(0);
        
        // Sequência de notas para fanfarra real
        const fanfareNotes = [
          392.00, // G4
          392.00, // G4
          587.33, // D5
          783.99, // G5
          659.25, // E5
          587.33, // D5
          523.25, // C5
          783.99  // G5
        ];
        
        const noteDuration = 0.25; // Duração de cada nota
        
        for (let i = 0; i < victoryBuffer.length; i++) {
          const t = i / this.audioContext.sampleRate;
          const noteIndex = Math.floor(t / noteDuration) % fanfareNotes.length;
          const noteT = t % noteDuration;
          const freq = fanfareNotes[noteIndex];
          
          // Simula trombetas com harmônicos
          const sound = 
            Math.sin(2 * Math.PI * freq * t) * 0.4 +
            Math.sin(4 * Math.PI * freq * t) * 0.2 +
            Math.sin(6 * Math.PI * freq * t) * 0.1;
          
          // Envelope para cada nota
          const envelope = Math.min(noteT * 10, 1) * Math.exp(-5 * noteT);
          
          victoryChannel[i] = sound * envelope;
        }
        
        this.playBuffer(victoryBuffer, 0.15);
        break;

      case 'defeat':
        // Som melancólico de derrota (como um lamento real)
        const defeatBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 2.0, this.audioContext.sampleRate);
        const defeatChannel = defeatBuffer.getChannelData(0);
        
        for (let i = 0; i < defeatBuffer.length; i++) {
          const t = i / this.audioContext.sampleRate;
          
          // Notas descendentes melancólicas
          const note1 = 440 * Math.pow(0.9, t * 2); // Frequência descendente
          const note2 = note1 * 1.5; // Harmônico superior
          
          // Combina as notas com tremolo
          const tremolo = 1 + Math.sin(2 * Math.PI * 5 * t) * 0.1;
          const sound = (
            Math.sin(2 * Math.PI * note1 * t) * 0.5 +
            Math.sin(2 * Math.PI * note2 * t) * 0.3
          ) * tremolo;
          
          // Envelope lento
          const envelope = Math.exp(-1 * t);
          
          defeatChannel[i] = sound * envelope;
        }
        
        this.playBuffer(defeatBuffer, 0.15);
        break;

      case 'error':
        // Som de erro (duas notas descendentes)
        const errorBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 0.2, this.audioContext.sampleRate);
        const errorChannel = errorBuffer.getChannelData(0);
        
        for (let i = 0; i < errorBuffer.length; i++) {
          const t = i / this.audioContext.sampleRate;
          const frequency = t < 0.1 ? 400 : 300;
          errorChannel[i] = Math.sin(2 * Math.PI * frequency * t) * 
                           Math.exp(-5 * t);
        }
        
        this.playBuffer(errorBuffer);
        break;
    }
  }

  toggle() {
    this.enabled = !this.enabled;
    return this.enabled;
  }

  isEnabled() {
    return this.enabled;
  }
}

export const gameSounds = new GameSounds(); 