/**
 * Gera uma URL de avatar baseada em um texto usando o serviço DiceBear
 * @param text Texto para gerar o avatar (nome, email, etc)
 * @returns URL do avatar gerado
 */
export function generateGradientAvatar(text: string): string {
  // Gera uma cor baseada no texto
  const stringToColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.floor(Math.abs(Math.sin(hash) * 16777215));
    return color.toString(16).padStart(6, '0');
  };

  // Gera uma segunda cor para o gradiente
  const getComplementaryColor = (color: string) => {
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);
    
    const complementary = {
      r: 255 - r,
      g: 255 - g,
      b: 255 - b
    };

    return `${complementary.r.toString(16).padStart(2, '0')}${complementary.g.toString(16).padStart(2, '0')}${complementary.b.toString(16).padStart(2, '0')}`;
  };

  const baseColor = stringToColor(text);
  const complementaryColor = getComplementaryColor(baseColor);

  // Usar o DiceBear para gerar um avatar com gradiente
  return `https://api.dicebear.com/7.x/shapes/svg?seed=${encodeURIComponent(text)}&backgroundColor=${baseColor},${complementaryColor}&backgroundType=gradientLinear`;
} 