import { useEffect, useState } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { toast } from 'sonner';
import { Database } from '@/integrations/supabase/types';

export function useRooms() {
  const session = useSession();
  const supabase = useSupabaseClient<Database>();
  const [rooms, setRooms] = useState<Database['public']['Tables']['games']['Row'][]>([]);
  const [loading, setLoading] = useState(true);
  const [guestName] = useState<string | null>(localStorage.getItem('guestName'));

  const fetchRooms = async () => {
    try {
      setLoading(true);
      console.log('Buscando salas...');
      
      // Buscar todas as salas ativas diretamente
      const { data, error } = await supabase
        .from('games')
        .select('*')
        .is('winner_id', null)
        .gt('updated_at', new Date(Date.now() - 5 * 60 * 1000).toISOString())
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // Filtrar apenas salas válidas
      const validRooms = data?.filter(room => {
        const hasPlayer1 = Boolean(room.player1_id || room.player1_name);
        const hasPlayer2 = Boolean(room.player2_id || room.player2_name);
        const isValid = (hasPlayer1 || hasPlayer2);
        return isValid;
      }) || [];

      console.log('Salas válidas encontradas:', validRooms);
      setRooms(validRooms);
    } catch (err) {
      console.error('Erro ao carregar salas:', err);
      toast.error('Erro ao carregar salas');
    } finally {
      setLoading(false);
    }
  };

  const deleteEmptyRoom = async (roomId: string) => {
    try {
      console.log('Removendo sala vazia:', roomId);
      const { error } = await supabase
        .from('games')
        .delete()
        .match({ room_id: roomId });

      if (error) throw error;
      console.log('Sala removida com sucesso:', roomId);
      await fetchRooms();
    } catch (err) {
      console.error('Erro ao remover sala:', err);
    }
  };

  useEffect(() => {
    fetchRooms();

    // Inscrever em todas as mudanças da tabela games
    const roomsSubscription = supabase
      .channel('public:games')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'games'
      }, (payload) => {
        console.log('Mudança detectada:', payload);
        // Atualizar imediatamente ao receber mudanças
        fetchRooms();
      })
      .subscribe();

    // Atualizar a cada 30 segundos para garantir
    const refreshInterval = setInterval(() => {
      fetchRooms();
    }, 30000);

    return () => {
      roomsSubscription.unsubscribe();
      clearInterval(refreshInterval);
    };
  }, [supabase]);

  const sendSystemMessage = async (gameId: string, message: string, eventType: string) => {
    try {
      const { error } = await supabase
        .from('chat_messages')
        .insert({
          game_id: gameId,
          message,
          is_system_message: true,
          system_event_type: eventType,
          created_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (err) {
      console.error('Erro ao enviar mensagem do sistema:', err);
    }
  };

  const leaveRoom = async (roomId: string) => {
    try {
      const { data: game } = await supabase
        .from('games')
        .select()
        .eq('room_id', roomId)
        .single();

      if (!game) return;

      let playerName = '';
      const updates: any = {};
      
      // Determinar o nome do jogador (seja convidado ou usuário logado)
      if (session?.user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('display_name')
          .eq('id', session.user.id)
          .single();
        playerName = profile?.display_name || session.user.email || 'Anônimo';
      } else {
        playerName = localStorage.getItem('guestName') || 'Anônimo';
      }

      // Primeiro enviar a mensagem do sistema
      if (game.player1_id === session?.user?.id || game.player1_name === playerName) {
        // Enviar mensagem do sistema primeiro
        await sendSystemMessage(
          game.id,
          `👋 ${playerName} saiu`,
          'player_left'
        );
        updates.player1_id = null;
        updates.player1_name = null;
      } else if (game.player2_id === session?.user?.id || game.player2_name === playerName) {
        // Enviar mensagem do sistema primeiro
        await sendSystemMessage(
          game.id,
          `👋 ${playerName} saiu`,
          'player_left'
        );
        updates.player2_id = null;
        updates.player2_name = null;
      }

      // Aguardar um pequeno delay para garantir que a mensagem foi processada
      await new Promise(resolve => setTimeout(resolve, 500));

      // Depois atualizar o jogo
      const { error } = await supabase
        .from('games')
        .update(updates)
        .eq('room_id', roomId);

      if (error) throw error;

      const { data: updatedGame } = await supabase
        .from('games')
        .select()
        .eq('room_id', roomId)
        .single();

      if (updatedGame && !updatedGame.player1_id && !updatedGame.player2_id) {
        await deleteEmptyRoom(roomId);
      }

      toast.success('Você saiu da sala');
      await fetchRooms();
    } catch (err) {
      console.error('Erro ao sair da sala:', err);
      toast.error('Erro ao sair da sala');
    }
  };

  const createRoom = async () => {
    try {
      console.log('Criando sala...');
      const roomId = Math.random().toString(36).substring(2, 8).toUpperCase();
      const initialBoard = Array(8).fill(0).map(() => Array(8).fill(0));
      
      for (let row = 0; row < 3; row++) {
        for (let col = 0; col < 8; col++) {
          if ((row + col) % 2 === 1) {
            initialBoard[row][col] = 1;
          }
        }
      }
      
      for (let row = 5; row < 8; row++) {
        for (let col = 0; col < 8; col++) {
          if ((row + col) % 2 === 1) {
            initialBoard[row][col] = 2;
          }
        }
      }

      let playerName = guestName || 'Anônimo';
      let playerId = null;

      if (session?.user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('display_name')
          .eq('id', session.user.id)
          .single();

        playerName = profile?.display_name || session.user.email || 'Anônimo';
        playerId = session.user.id;
      }

      const { data, error } = await supabase
        .from('games')
        .insert({
          room_id: roomId,
          board: initialBoard,
          current_player: 1,
          player1_id: playerId,
          player1_name: playerName,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        })
        .select()
        .single();

      if (error) {
        console.error('Erro ao criar sala:', error);
        throw error;
      }

      // Enviar mensagem do sistema quando a sala é criada
      await sendSystemMessage(
        data.id,
        `👋 ${playerName} entrou`,
        'player_joined'
      );

      console.log('Sala criada com sucesso:', data);
      toast.success('Sala criada com sucesso!');
      await fetchRooms();
      return data;
    } catch (err) {
      console.error('Erro ao criar sala:', err);
      toast.error('Erro ao criar sala');
      return null;
    }
  };

  const cleanupRooms = async () => {
    if (!session?.user) return;

    try {
      console.log('Iniciando limpeza de salas...');
      
      const { data: rooms, error } = await supabase
        .from('games')
        .select('*')
        .or('player1_id.is.null,player2_id.is.null')
        .or(`updated_at.lt.${new Date(Date.now() - 5 * 60 * 1000).toISOString()}`);

      if (error) throw error;

      const roomsToDelete = rooms?.filter(room => 
        (!room.player1_id && !room.player2_id) || // Sem jogadores
        (room.player1_name && !room.player1_id) || // Dados inconsistentes
        (room.player2_name && !room.player2_id) || // Dados inconsistentes
        new Date(room.updated_at).getTime() < Date.now() - 5 * 60 * 1000 // Inativa
      ).map(room => room.room_id) || [];

      if (roomsToDelete.length > 0) {
        const { error: deleteError } = await supabase
          .from('games')
          .delete()
          .in('room_id', roomsToDelete);

        if (!deleteError) {
          console.log(`Salas removidas: ${roomsToDelete.join(', ')}`);
          toast.success(`${roomsToDelete.length} salas antigas foram removidas`);
        }
      } else {
        console.log('Nenhuma sala para limpar');
        toast.info('Nenhuma sala para limpar');
      }

      await fetchRooms();
    } catch (err) {
      console.error('Erro ao limpar salas:', err);
      toast.error('Erro ao limpar salas');
    }
  };

  return {
    rooms,
    loading,
    createRoom,
    leaveRoom,
    cleanupRooms,
    refetch: fetchRooms
  };
} 