type GradientPair = {
  from: string;
  to: string;
};

const gradientPairs: GradientPair[] = [
  { from: '#FF416C', to: '#FF4B2B' }, // Vermelho vibrante
  { from: '#4776E6', to: '#8E54E9' }, // Azul para roxo
  { from: '#00B4DB', to: '#0083B0' }, // Azul oceano
  { from: '#FF8008', to: '#FFC837' }, // Laranja para amarelo
  { from: '#834D9B', to: '#D04ED6' }, // Roxo profundo
  { from: '#4CB8C4', to: '#3CD3AD' }, // Verde água
  { from: '#DD5E89', to: '#F7BB97' }, // Rosa para pêssego
  { from: '#56CCF2', to: '#2F80ED' }, // Azul claro
  { from: '#A8C0FF', to: '#3F2B96' }, // Azul noturno
  { from: '#11998e', to: '#38ef7d' }, // Verde esmeralda
];

export function generateGradientAvatar(seed: string): string {
  // Remover @ se existir no início da string
  const cleanSeed = seed.startsWith('@') ? seed.slice(1) : seed;
  
  // Gerar um índice baseado na string limpa
  const hashCode = cleanSeed.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  
  const index = Math.abs(hashCode) % gradientPairs.length;
  const pair = gradientPairs[index];
  
  // Pegar a primeira letra da string limpa
  const firstLetter = cleanSeed.charAt(0).toUpperCase();
  
  // Criar SVG com gradiente
  return `data:image/svg+xml,${encodeURIComponent(`
    <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style="stop-color:${pair.from}"/>
          <stop offset="100%" style="stop-color:${pair.to}"/>
        </linearGradient>
      </defs>
      <rect width="100" height="100" fill="url(#gradient)"/>
      <text 
        x="50%" 
        y="50%" 
        font-family="Arial" 
        font-size="40" 
        fill="white" 
        text-anchor="middle" 
        dy=".3em"
        opacity="0.8"
      >
        ${firstLetter}
      </text>
    </svg>
  `)}`;
} 