import { useState, useEffect } from 'react';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { Trophy, Target, Swords, X } from 'lucide-react';
import { motion } from 'framer-motion';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { generateGradientAvatar } from '@/lib/avatar';
import { Database } from '@/integrations/supabase/types';

type Profile = Database['public']['Tables']['profiles']['Row'];

interface PlayerProfileCardProps {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
}

export function PlayerProfileCard({ userId, isOpen, onClose }: PlayerProfileCardProps) {
  const supabase = useSupabaseClient<Database>();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [stats, setStats] = useState<any>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) return;

    const loadProfileAndStats = async () => {
      try {
        // Carregar perfil
        const { data: profileData } = await supabase
          .from('profiles')
          .select(`
            *,
            avatar_skins (
              image_url
            )
          `)
          .eq('id', userId)
          .single();

        if (profileData) {
          setProfile(profileData);

          // Carregar avatar (incluindo skins)
          if (profileData.equipped_skin_id) {
            const { data: skin } = await supabase
              .from('avatar_skins')
              .select('image_url')
              .eq('id', profileData.equipped_skin_id)
              .single();
            
            if (skin?.image_url) {
              setAvatarUrl(skin.image_url);
            }
          } else {
            setAvatarUrl(generateGradientAvatar(profileData.username || 'A'));
          }

          // Carregar estatísticas
          const { data: statsData } = await supabase
            .from('game_stats')
            .select('*')
            .eq('user_id', userId)
            .maybeSingle();

          // Se não existir estatísticas, criar um registro inicial
          if (!statsData) {
            const { data: newStats, error: createStatsError } = await supabase
              .from('game_stats')
              .insert({
                user_id: userId,
                wins: 0,
                losses: 0
              })
              .select()
              .single();

            if (createStatsError) throw createStatsError;
            setStats(newStats);
          } else {
            setStats(statsData);
          }
        }
      } catch (error) {
        console.error('Erro ao carregar perfil:', error);
      } finally {
        setLoading(false);
      }
    };

    loadProfileAndStats();
  }, [userId, supabase]);

  if (!isOpen) return null;

  const winRate = stats ? Math.round((stats.wins / (stats.wins + stats.losses)) * 100) : 0;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="relative w-full max-w-md p-4"
        onClick={e => e.stopPropagation()}
      >
        {loading ? (
          <Card className="bg-[#000510] border-white/20">
            <CardHeader className="flex flex-row items-center gap-4 pb-2">
              <div className="w-20 h-20 rounded-full bg-white/10 animate-pulse" />
              <div className="flex-1 space-y-2">
                <div className="h-5 bg-white/10 rounded animate-pulse" />
                <div className="h-4 bg-white/10 rounded w-2/3 animate-pulse" />
              </div>
            </CardHeader>
          </Card>
        ) : (
          <Card className="bg-[#000510] border-white/20">
            <button
              onClick={onClose}
              type="button"
              aria-label="Fechar perfil do jogador"
              className="absolute -top-2 -right-2 p-2 text-white/80 hover:text-white transition-colors bg-[#000510] rounded-full border border-white/20 z-10"
            >
              <X className="w-5 h-5" />
            </button>

            <CardHeader className="flex flex-row items-center gap-4 pb-4">
              <Avatar className="w-20 h-20 ring-4 ring-white/20">
                <AvatarImage 
                  src={avatarUrl || undefined}
                  alt={profile?.display_name || profile?.username}
                />
                <AvatarFallback className="bg-white/10 text-white text-2xl">
                  {profile?.display_name?.charAt(0) || profile?.username?.charAt(0) || '?'}
                </AvatarFallback>
              </Avatar>
              <div className="flex-1">
                <CardTitle className="text-2xl text-white">
                  {profile?.display_name || profile?.username || 'Jogador'}
                </CardTitle>
                <CardDescription className="text-white/80">
                  @{profile?.username}
                </CardDescription>
              </div>
            </CardHeader>

            <CardContent>
              <div className="flex items-center justify-between border-t border-white/10 pt-4">
                <div className="flex items-center gap-2">
                  <Trophy className="w-5 h-5 text-yellow-500" />
                  <div>
                    <div className="text-sm text-white/60">Vitórias</div>
                    <div className="text-lg font-bold text-white">{stats?.wins || 0}</div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Target className="w-5 h-5 text-blue-500" />
                  <div>
                    <div className="text-sm text-white/60">Taxa de Vitória</div>
                    <div className="text-lg font-bold text-white">{winRate}%</div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Swords className="w-5 h-5 text-red-500" />
                  <div>
                    <div className="text-sm text-white/60">Partidas</div>
                    <div className="text-lg font-bold text-white">{stats ? stats.wins + stats.losses : 0}</div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </motion.div>
    </motion.div>
  );
} 