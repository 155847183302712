import React, { useEffect, useRef } from 'react';
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { Clock } from "lucide-react";
import { supabase } from '@/integrations/supabase/client';

interface GameHistoryProps {
  gameId: string;
  moveHistory: Array<{
    player: number;
    from: string;
    to: string;
    type: string;
  }>;
  player1Name?: string;
  player2Name?: string;
}

export function GameHistory({ 
  gameId, 
  moveHistory: initialMoveHistory,
  player1Name = "Jogador 1", 
  player2Name = "Jogador 2"
}: GameHistoryProps) {
  const historyEndRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const channelRef = useRef<any>(null);
  const [moveHistory, setMoveHistory] = React.useState(initialMoveHistory);

  // Função para rolar até o final
  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollContainer) {
        // Usar scrollIntoView no último elemento
        const lastMove = scrollContainer.lastElementChild?.lastElementChild;
        lastMove?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        
        // Forçar scroll para garantir que chegue ao final
        setTimeout(() => {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }, 100);
      }
    }
  };

  useEffect(() => {
    if (!gameId) return;

    // Usar o mesmo canal do jogo para todas as atualizações
    const channel = supabase
      .channel(`game:${gameId}:moves`, {
        config: {
          broadcast: { self: true }
        }
      });

    channel
      .on('broadcast', { event: 'new_move' }, ({ payload }) => {
        setMoveHistory(prev => [...prev, payload]);
        scrollToBottom();
      })
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'moves',
          filter: `game_id=eq.${gameId}`
        },
        async (payload) => {
          console.log('Novo movimento detectado:', payload);
          // Determinar o jogador baseado na posição inicial
          // Peças nas linhas a,c,e,g são do jogador 1 (vermelho)
          // Peças nas linhas b,d,f,h são do jogador 2 (azul)
          const isPlayer1Move = payload.new.from_position.match(/^[aceg]/i);
          
          const newMove = {
            player: payload.new.current_player,
            from: payload.new.from_position,
            to: payload.new.to_position,
            type: payload.new.is_capture ? 'captura' : 
                  payload.new.is_promotion ? 'promoção' : 
                  'movimento'
          };
          
          setMoveHistory(prev => [...prev, newMove]);
          scrollToBottom();
        }
      )
      .subscribe((status) => {
        console.log('Move subscription status:', status);
      });

    return () => {
      channel.unsubscribe();
    };
  }, [gameId]);

  useEffect(() => {
    setMoveHistory(initialMoveHistory);
    scrollToBottom();
  }, [initialMoveHistory]);

  useEffect(() => {
    scrollToBottom();
  }, [moveHistory]);

  const getMoveTypeIcon = (type: string) => {
    switch (type) {
      case 'captura':
        return '⚔️';
      case 'promoção':
        return '👑';
      default:
        return '→';
    }
  };

  const getMoveTypeClass = (type: string) => {
    switch (type) {
      case 'captura':
        return 'text-red-400';
      case 'promoção':
        return 'text-yellow-400';
      default:
        return 'text-blue-400';
    }
  };

  const getPlayerName = (player: number) => {
    return player === 1 ? player1Name : player2Name;
  };

  const getPlayerColor = (player: number) => {
    return player === 1 ? 'text-red-400' : 'text-blue-400';
  };

  return (
    <ScrollArea ref={scrollAreaRef} className="h-full">
      <div className="p-2">
        {moveHistory.length === 0 ? (
          <div className="text-sm text-zinc-500 text-center py-4">
            Nenhum movimento realizado ainda
          </div>
        ) : (
          <div className="space-y-1">
            {moveHistory.map((move, i) => (
              <div 
                key={i} 
                className={cn(
                  "flex items-center gap-1.5 py-1 px-1.5 rounded text-xs",
                  "hover:bg-zinc-800/50 transition-colors",
                  "group"
                )}
              >
                <span className="text-xs text-zinc-500 shrink-0 w-5">
                  #{i + 1}
                </span>
                <span className={cn(
                  "font-medium shrink-0 truncate max-w-[60px]",
                  getPlayerColor(move.player)
                )}>
                  {getPlayerName(move.player)}
                </span>
                <div className="flex items-center gap-1 text-zinc-400 min-w-0 flex-1">
                  <span className="font-mono bg-zinc-800/50 px-1 py-0.5 rounded shrink-0">
                    {move.from}
                  </span>
                  <span className={cn(
                    "transition-transform group-hover:scale-110 shrink-0",
                    getMoveTypeClass(move.type)
                  )}>
                    {getMoveTypeIcon(move.type)}
                  </span>
                  <span className="font-mono bg-zinc-800/50 px-1 py-0.5 rounded shrink-0">
                    {move.to}
                  </span>
                </div>
                <span className={cn(
                  "text-xs px-1.5 py-0.5 rounded-full shrink-0",
                  getMoveTypeClass(move.type),
                  "bg-zinc-800/50"
                )}>
                  {move.type}
                </span>
              </div>
            ))}
            <div ref={historyEndRef} />
          </div>
        )}
      </div>
    </ScrollArea>
  );
} 