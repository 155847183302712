import { useEffect, useState } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { Database } from '@/integrations/supabase/types';
import { toast } from 'sonner';

type BaseProfile = Database['public']['Tables']['profiles']['Row'];
type AvatarSkin = Database['public']['Tables']['avatar_skins']['Row'];

type Profile = BaseProfile & {
  avatar_skin?: {
    image_url: string;
  } | null;
};

type ProfileUpdate = Database['public']['Tables']['profiles']['Update'];

export function useProfile() {
  const session = useSession();
  const supabase = useSupabaseClient<Database>();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!session?.user) return;

    const fetchProfile = async () => {
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select(`
            *,
            avatar_skin:avatar_skins!equipped_skin_id (
              image_url
            )
          `)
          .eq('id', session.user.id)
          .single();

        if (error) throw error;
        setProfile(data);
      } catch (error) {
        console.error('Erro ao carregar perfil:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();

    // Inscrever para atualizações em tempo real
    const channel = supabase
      .channel(`public:profiles:id=eq.${session.user.id}`)
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'profiles',
        filter: `id=eq.${session.user.id}`
      }, (payload) => {
        setProfile(payload.new as Profile);
      })
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [session, supabase]);

  const updateProfile = async (updates: ProfileUpdate) => {
    if (!session?.user) throw new Error('Usuário não autenticado');

    try {
      const { data, error } = await supabase
        .from('profiles')
        .update(updates)
        .eq('id', session.user.id)
        .select(`
          *,
          avatar_skin:avatar_skins!equipped_skin_id (
            image_url
          )
        `)
        .single();

      if (error) throw error;

      setProfile(data);
      return data;
    } catch (error: any) {
      console.error('Erro ao atualizar perfil:', error);
      toast.error(error.message || 'Erro ao atualizar perfil');
      throw error;
    }
  };

  return {
    profile,
    loading,
    updateProfile
  };
} 