import { useState, useEffect } from 'react';

export function useGameTimer(timeLimit: number = 60) {
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isActive, timeLeft]);

  return {
    timeLeft,
    startTimer: () => {
      setTimeLeft(timeLimit);
      setIsActive(true);
    },
    stopTimer: () => setIsActive(false),
    resetTimer: () => setTimeLeft(timeLimit)
  };
} 