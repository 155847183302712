import React, { memo } from 'react';
import { cn } from '@/lib/utils';
import { Crown } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface CheckerBoardProps {
  board: number[][];
  onSquareClick: (row: number, col: number) => void;
  selectedPiece: { row: number; col: number } | null;
  validMoves: { row: number; col: number; isCapture?: boolean }[];
  currentPlayer: 1 | 2;
  gameStarted: boolean;
  isPlayer2: boolean;
  mandatoryPieces?: { row: number; col: number }[];
  isMyTurn: boolean;
  isMobile: boolean;
  canJoinGame?: boolean;
  onJoinGame?: () => void;
  player1Id?: string | null;
  player2Id?: string | null;
}

const Piece = memo(({ 
  pieceType, 
  isSelected, 
  isMandatory, 
  canInteract, 
  isMobile, 
  onClick 
}: { 
  pieceType: number;
  isSelected: boolean;
  isMandatory: boolean;
  canInteract: boolean;
  isMobile: boolean;
  onClick: () => void;
}) => {
  const piecePlayer = pieceType === 1 || pieceType === 3 ? 1 : 2;
  const isKing = pieceType === 3 || pieceType === 4;

  return (
    <div
      className={cn(
        'absolute inset-2 rounded-full border-4 flex items-center justify-center shadow-lg transition-transform cursor-pointer',
        isMobile ? 'inset-1.5' : 'inset-1',
        piecePlayer === 1 
          ? 'bg-red-500 border-red-600 dark:bg-red-600 dark:border-red-700'
          : 'bg-blue-500 border-blue-600 dark:bg-blue-600 dark:border-blue-700',
        isSelected && canInteract && 'ring-2 ring-white/50',
        isMandatory && 'ring-2 ring-yellow-400/50'
      )}
      onClick={onClick}
    >
      {isKing && (
        <Crown 
          className={cn(
            'w-5 h-5',
            piecePlayer === 1 ? 'text-red-200' : 'text-blue-200'
          )} 
        />
      )}
    </div>
  );
});

Piece.displayName = 'Piece';

const Square = memo(({ 
  row, 
  col, 
  piece, 
  isBlackSquare,
  letter,
  number,
  validMove,
  isSelected,
  isMandatory,
  canInteract,
  isMobile,
  onSquareClick
}: {
  row: number;
  col: number;
  piece: number;
  isBlackSquare: boolean;
  letter?: string | null;
  number?: string | null;
  validMove: boolean;
  isSelected: boolean;
  isMandatory: boolean;
  canInteract: boolean;
  isMobile: boolean;
  onSquareClick: () => void;
}) => {
  return (
    <div
      className={cn(
        "relative w-full h-full flex items-center justify-center",
        isBlackSquare ? "bg-zinc-800" : "bg-zinc-700",
        validMove && "after:absolute after:inset-0 after:bg-green-500/20 after:rounded-full after:scale-50 hover:after:scale-75 after:transition-transform",
        canInteract && validMove && "cursor-pointer"
      )}
      onClick={() => canInteract && validMove && onSquareClick()}
    >
      {number && (
        <span className={cn(
          "absolute left-1 top-1 text-zinc-400 font-medium",
          isMobile ? "text-[10px]" : "text-xs"
        )}>
          {number}
        </span>
      )}

      {letter && (
        <span className={cn(
          "absolute right-1 bottom-1 text-zinc-400 font-medium",
          isMobile ? "text-[10px]" : "text-xs"
        )}>
          {letter}
        </span>
      )}

      {piece !== 0 && (
        <Piece
          pieceType={piece}
          isSelected={isSelected}
          isMandatory={isMandatory}
          canInteract={canInteract}
          isMobile={isMobile}
          onClick={onSquareClick}
        />
      )}
    </div>
  );
});

Square.displayName = 'Square';

const CheckerBoard: React.FC<CheckerBoardProps> = ({
  board,
  onSquareClick,
  selectedPiece,
  validMoves,
  currentPlayer,
  gameStarted,
  isPlayer2,
  mandatoryPieces = [],
  isMyTurn,
  isMobile,
  canJoinGame,
  onJoinGame,
  player1Id,
  player2Id
}) => {
  const getPlayerBoard = (): number[][] => {
    const playerBoard = [...board.map(row => [...row])];
    return isPlayer2 ? playerBoard : playerBoard.reverse().map(row => row.reverse());
  };

  const convertCoordinates = (row: number, col: number) => {
    if (!isPlayer2) {
      return {
        row: 7 - row,
        col: 7 - col
      };
    }
    return { row, col };
  };

  const getPositionName = (row: number, col: number): string => {
    if (!isPlayer2) {
      const file = String.fromCharCode(97 + col);
      const rank = 8 - row;
      return `${file}${rank}`;
    } else {
      const file = String.fromCharCode(97 + (7 - col));
      const rank = row + 1;
      return `${file}${rank}`;
    }
  };

  const getRealBoardPosition = (row: number, col: number): string => {
    const { row: realRow, col: realCol } = convertCoordinates(row, col);
    const file = String.fromCharCode(97 + (7 - realCol));
    const rank = realRow + 1;
    return `${file}${rank}`;
  };

  const renderSquare = (row: number, col: number, letter?: string | null, number?: string | null) => {
    const isBlackSquare = (row + col) % 2 === 1;
    const piece = getPlayerBoard()[row][col];
    const { row: originalRow, col: originalCol } = convertCoordinates(row, col);
    const validMove = validMoves.some(move => 
      move.row === originalRow && move.col === originalCol
    );
    const isSelected = selectedPiece?.row === originalRow && selectedPiece?.col === originalCol;
    const isMandatory = mandatoryPieces.some(p => p.row === originalRow && p.col === originalCol);
    const canInteract = gameStarted && isMyTurn;

    return (
      <Square
        key={`${row}-${col}`}
        row={row}
        col={col}
        piece={piece}
        isBlackSquare={isBlackSquare}
        letter={letter}
        number={number}
        validMove={validMove}
        isSelected={isSelected}
        isMandatory={isMandatory}
        canInteract={canInteract}
        isMobile={isMobile}
        onSquareClick={() => onSquareClick(originalRow, originalCol)}
      />
    );
  };

  return (
    <div className={cn(
      "relative aspect-square w-full bg-[#232326] shadow-2xl",
      isMobile ? "p-0 max-w-[550px]" : "p-0 md:max-w-[750px]",
      "mx-auto"
    )}>
      <div className="relative w-full h-full grid grid-cols-8 gap-0">
        {Array(8).fill(0).map((_, row) =>
          Array(8).fill(0).map((_, col) => {
            const isFirstRow = row === 7;
            const isFirstCol = col === 0;
            const letter = isFirstRow ? (isPlayer2 ? 'HGFEDCBA' : 'ABCDEFGH')[col] : null;
            const number = isFirstCol ? (isPlayer2 ? row + 1 : 8 - row).toString() : null;
            
            return renderSquare(row, col, letter, number);
          })
        )}
      </div>

      {!gameStarted && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm rounded-lg">
          <div className="text-center text-white bg-zinc-900/90 p-4 lg:p-6 rounded-xl shadow-xl border border-zinc-700">
            <h3 className="font-bold mb-2 text-lg">
              Aguardando Segundo Jogador
            </h3>
            <p className="text-zinc-400 mb-4">
              O jogo começará quando outro jogador entrar na partida
            </p>
            {canJoinGame && (
              <Button
                onClick={onJoinGame}
                className="bg-emerald-600 hover:bg-emerald-700 text-white"
              >
                Entrar na Partida
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(CheckerBoard);