import { useEffect, useState, useRef, useCallback } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { toast } from 'sonner';
import { Database } from '@/integrations/supabase/types';
import { supabase } from '@/integrations/supabase/client';
import maskSensitiveInfo from '@/integrations/supabase/utils';

type Game = Database['public']['Tables']['games']['Row'];

interface ValidMove {
  row: number;
  col: number;
  isCapture?: boolean;
  capturedRow?: number;
  capturedCol?: number;
}

export function useGame(roomId: string) {
  const session = useSession();
  const supabase = useSupabaseClient<Database>();
  const [game, setGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const channelRef = useRef<any>(null);
  const [guestName] = useState<string | null>(localStorage.getItem('guestName'));

  // Função para atualizar o estado do jogo localmente
  const updateGameState = useCallback((newGameData: Game) => {
    setGame(prev => {
      // Se não houver estado anterior, usar o novo estado
      if (!prev) return newGameData;

      // Se o novo estado for mais recente, atualizar
      if (new Date(newGameData.updated_at) > new Date(prev.updated_at)) {
        return newGameData;
      }

      // Caso contrário, manter o estado atual
      return prev;
    });
    setLoading(false);
  }, []);

  // Mover fetchGame para useCallback para manter a referência
  const fetchGame = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('games')
        .select()
        .eq('room_id', roomId)
        .single();

      if (error) throw error;
      updateGameState(data);
      setLoading(false);
    } catch (err) {
      console.error('Erro ao carregar jogo:', err);
      setError('Erro ao carregar o jogo');
      setLoading(false);
    }
  }, [roomId, supabase, updateGameState]);

  useEffect(() => {
    fetchGame();

    // Configurar canal realtime
    const channel = supabase
      .channel(`game:${roomId}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'games',
          filter: `room_id=eq.${roomId}`
        },
        (payload) => {
          console.log('Atualização do jogo recebida via postgres:', payload);
          if (payload.new) {
            updateGameState(payload.new as Game);
          }
        }
      )
      .on(
        'broadcast',
        { event: 'game_update' },
        ({ payload }) => {
          console.log('Atualização do jogo recebida via broadcast:', payload);
          
          // Extrair o estado do jogo do payload
          const gameState = { ...payload };
          delete gameState.lastMove;

          // Atualizar o estado do jogo
          updateGameState(gameState as Game);
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Inscrito no canal do jogo:', roomId);
        }
      });

    channelRef.current = channel;

    return () => {
      if (channelRef.current) {
        channelRef.current.unsubscribe();
      }
    };
  }, [roomId, fetchGame, updateGameState]);

  const sendSystemMessage = async (gameId: string, message: string, eventType: string) => {
    try {
      const { error } = await supabase
        .from('chat_messages')
        .insert({
          game_id: gameId,
          message,
          is_system_message: true,
          system_event_type: eventType,
          created_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (err) {
      console.error('Erro ao enviar mensagem do sistema:', err);
    }
  };

  const joinGame = async () => {
    try {
      // Forçar refetch do jogo antes de tentar entrar
      await fetchGame();
      
      if (!game) {
        console.error('Jogo não encontrado após refetch');
        return;
      }

      // Log detalhado do estado atual
      console.log('Estado atual do jogo:', {
        game_id: game.id,
        room_id: game.room_id,
        player1_id: game.player1_id,
        player2_id: game.player2_id,
        current_user: session?.user?.id || 'guest'
      });

      // Verificar se o usuário já está no jogo
      const isGuest = !session?.user;
      const guestNameFromStorage = localStorage.getItem('guestName');

      if (session?.user) {
        if (game.player1_id === session.user.id) {
          console.log('Usuário já é o jogador 1');
          return;
        }

        if (game.player2_id === session.user.id) {
          console.log('Usuário já é o jogador 2');
          return;
        }
      } else {
        // Para convidados, verificar pelo nome
        if (game.player1_name === guestNameFromStorage) {
          console.log('Convidado já é o jogador 1');
          return;
        }

        if (game.player2_name === guestNameFromStorage) {
          console.log('Convidado já é o jogador 2');
          return;
        }
      }

      let updates = {};

      // Se não há jogador 1, adicionar como jogador 1
      if (!game.player1_id && !game.player1_name) {
        if (session?.user) {
          const { data: profile } = await supabase
            .from('profiles')
            .select('display_name')
            .eq('id', session.user.id)
            .single();

          updates = {
            player1_id: session.user.id,
            player1_name: profile?.display_name || session.user.email,
            status: 'waiting',
            updated_at: new Date().toISOString()
          };

          // Enviar mensagem do sistema
          await sendSystemMessage(
            game.id,
            `👋 ${profile?.display_name || session.user.email} entrou`,
            'player_joined'
          );
        } else {
          updates = {
            player1_id: null,
            player1_name: guestNameFromStorage || 'Anônimo',
            status: 'waiting',
            updated_at: new Date().toISOString()
          };

          // Enviar mensagem do sistema para jogador convidado
          await sendSystemMessage(
            game.id,
            `👋 ${guestNameFromStorage || 'Anônimo'} entrou`,
            'player_joined'
          );
        }
        console.log('Tentando entrar como jogador 1');
      }
      // Se não há jogador 2, adicionar como jogador 2
      else if ((!game.player2_id && !game.player2_name) && (game.player1_id || game.player1_name)) {
        if (session?.user) {
          const { data: profile } = await supabase
            .from('profiles')
            .select('display_name')
            .eq('id', session.user.id)
            .single();

          updates = {
            player2_id: session.user.id,
            player2_name: profile?.display_name || session.user.email,
            status: 'playing',
            updated_at: new Date().toISOString()
          };

          // Enviar mensagem do sistema
          await sendSystemMessage(
            game.id,
            `👋 ${profile?.display_name || session.user.email} entrou`,
            'player_joined'
          );
        } else {
          updates = {
            player2_id: null,
            player2_name: guestNameFromStorage || 'Anônimo',
            status: 'playing',
            updated_at: new Date().toISOString()
          };

          // Enviar mensagem do sistema para jogador convidado
          await sendSystemMessage(
            game.id,
            `👋 ${guestNameFromStorage || 'Anônimo'} entrou`,
            'player_joined'
          );
        }
        console.log('Tentando entrar como jogador 2');
      } else {
        console.log('Jogo já está cheio');
        return;
      }

      // Log dos dados que serão atualizados
      console.log('Dados para atualização:', {
        updates,
        room_id: roomId
      });

      // Primeiro, fazer a atualização sem tentar retornar os dados
      const { error: updateError } = await supabase
        .from('games')
        .update(updates)
        .eq('id', game.id);

      if (updateError) {
        console.error('Erro ao atualizar jogo:', updateError);
        toast.error('Erro ao entrar no jogo. Por favor, tente novamente.');
        throw updateError;
      }

      // Depois, buscar os dados atualizados separadamente
      const { data: updatedGame, error: fetchError } = await supabase
        .from('games')
        .select('*')
        .eq('id', game.id)
        .maybeSingle();

      if (fetchError) {
        console.error('Erro ao buscar jogo atualizado:', fetchError);
        toast.error('Erro ao verificar atualização do jogo');
        throw fetchError;
      }

      if (!updatedGame) {
        console.error('Jogo não encontrado após atualização');
        toast.error('Erro: Jogo não encontrado após atualização');
        return;
      }

      console.log('Jogador adicionado com sucesso:', updatedGame);
      toast.success('Você entrou no jogo!');
      
      // Atualizar estado local
      updateGameState(updatedGame);

      // Enviar evento de jogador entrou
      const channel = supabase.channel(`game_room_${game.id}`);
      await channel.send({
        type: 'broadcast',
        event: 'player_joined',
        payload: {
          playerId: session?.user?.id || null,
          playerName: session?.user ? updatedGame.player1_name || updatedGame.player2_name : guestNameFromStorage,
          isPlayer1: !game.player1_id && !game.player1_name
        }
      });

    } catch (error) {
      console.error('Erro ao entrar no jogo:', maskSensitiveInfo(error));
      toast.error('Erro ao entrar no jogo');
    }
  };

  const calculateValidMovesForSimulation = (
    row: number, 
    col: number, 
    simulatedBoard: number[][], 
    currentPlayer: number
  ): ValidMove[] => {
    const moves: ValidMove[] = [];
    const piece = simulatedBoard[row][col];
    const isKing = piece === 3 || piece === 4;
    const player = currentPlayer;

    const isValidPosition = (r: number, c: number) => r >= 0 && r < 8 && c >= 0 && c < 8;

    const isOpponentPiece = (r: number, c: number) => {
      const targetPiece = simulatedBoard[r][c];
      if (player === 1) {
        return targetPiece === 2 || targetPiece === 4;
      } else {
        return targetPiece === 1 || targetPiece === 3;
      }
    };

    // Função para verificar capturas da rainha
    const checkKingCapture = (r: number, c: number, dr: number, dc: number) => {
      let currentRow = r + dr;
      let currentCol = c + dc;
      let foundOpponent = false;
      let opponentRow = -1;
      let opponentCol = -1;

      while (isValidPosition(currentRow, currentCol)) {
        if (simulatedBoard[currentRow][currentCol] !== 0) {
          if (isOpponentPiece(currentRow, currentCol) && !foundOpponent) {
            foundOpponent = true;
            opponentRow = currentRow;
            opponentCol = currentCol;
            currentRow += dr;
            currentCol += dc;
          } else {
            break;
          }
        } else if (foundOpponent) {
          moves.push({
            row: currentRow,
            col: currentCol,
            isCapture: true,
            capturedRow: opponentRow,
            capturedCol: opponentCol
          });
          break;
        } else {
          currentRow += dr;
          currentCol += dc;
        }
      }
    };

    // Função para verificar capturas normais
    const checkNormalCapture = (r: number, c: number, dr: number, dc: number) => {
      // Removemos a verificação de direção para capturas
      // Peças normais podem capturar em qualquer direção
      const jumpRow = r + dr;
      const jumpCol = c + dc;
      const captureRow = r + dr * 2;
      const captureCol = c + dc * 2;

      if (
        isValidPosition(captureRow, captureCol) &&
        isOpponentPiece(jumpRow, jumpCol) &&
        simulatedBoard[captureRow][captureCol] === 0
      ) {
        moves.push({
          row: captureRow,
          col: captureCol,
          isCapture: true,
          capturedRow: jumpRow,
          capturedCol: jumpCol
        });
      }
    };

    // Verificar todas as direções para capturas
    const directions = [[-1, -1], [-1, 1], [1, -1], [1, 1]];
    
    if (isKing) {
      directions.forEach(([dr, dc]) => {
        checkKingCapture(row, col, dr, dc);
      });
    } else {
      directions.forEach(([dr, dc]) => {
        checkNormalCapture(row, col, dr, dc);
      });
    }

    return moves;
  };

  // Função para obter a posição real do tabuleiro
  const getRealBoardPosition = (row: number, col: number): string => {
    const file = String.fromCharCode(97 + (7 - col)); // 'h' to 'a' da direita para esquerda
    const rank = row + 1; // 1 to 8 de cima para baixo
    return `${file}${rank}`;
  };

  const makeMove = async (
    fromRow: number,
    fromCol: number,
    toRow: number,
    toCol: number,
    isCapture: boolean,
    isPromotion: boolean,
    capturedRow?: number,
    capturedCol?: number,
    keepTurn: boolean = false
  ) => {
    if (!game) return;

    try {
      const newBoard = JSON.parse(JSON.stringify(game.board));
      const piece = newBoard[fromRow][fromCol];
      newBoard[toRow][toCol] = isPromotion ? (game.current_player === 1 ? 3 : 4) : piece;
      newBoard[fromRow][fromCol] = 0;

      let hasMoreCaptures = false;
      let shouldChangeTurn = !keepTurn;

      if (isCapture && capturedRow !== undefined && capturedCol !== undefined) {
        newBoard[capturedRow][capturedCol] = 0;

        const moreCaptures = calculateValidMovesForSimulation(
          toRow,
          toCol,
          newBoard,
          game.current_player
        );

        hasMoreCaptures = moreCaptures.some(move => move.isCapture);

        if (hasMoreCaptures) {
          shouldChangeTurn = false;
          toast.info('Você deve continuar capturando com a mesma peça!');
        } else {
          shouldChangeTurn = true;
        }
      }

      // Verificar vitória
      let winner_id = null;
      let player1Pieces = 0;
      let player2Pieces = 0;

      for (let row = 0; row < 8; row++) {
        for (let col = 0; col < 8; col++) {
          const p = newBoard[row][col];
          if (p === 1 || p === 3) player1Pieces++;
          if (p === 2 || p === 4) player2Pieces++;
        }
      }

      let newStatus = game.status;
      
      if (player1Pieces === 0) {
        winner_id = game.player2_id || null;
        newStatus = 'finished';
      }
      if (player2Pieces === 0) {
        winner_id = game.player1_id || null;
        newStatus = 'finished';
      }

      // Criar novo estado do jogo
      const newGameState = {
        ...game,
        board: newBoard,
        current_player: shouldChangeTurn ? (game.current_player === 1 ? 2 : 1) : game.current_player,
        winner_id,
        status: newStatus,
        updated_at: new Date().toISOString()
      };

      // Atualizar estado local imediatamente
      updateGameState(newGameState);

      try {
        // Atualizar o estado do jogo no banco
        const { error: updateError } = await supabase
          .from('games')
          .update({
            board: newBoard,
            current_player: newGameState.current_player,
            winner_id,
            status: newStatus,
            updated_at: newGameState.updated_at
          })
          .eq('id', game.id);

        if (updateError) throw updateError;

        // Salvar o movimento
        const moveData = {
          game_id: game.id,
          player_id: session?.user?.id || null,
          player_name: session?.user ? null : localStorage.getItem('guestName'),
          from_row: fromRow,
          from_col: fromCol,
          to_row: toRow,
          to_col: toCol,
          from_position: getRealBoardPosition(fromRow, fromCol),
          to_position: getRealBoardPosition(toRow, toCol),
          is_capture: isCapture,
          is_promotion: isPromotion,
          current_player: game.current_player,
          created_at: new Date().toISOString()
        };

        const { error: moveError } = await supabase
          .from('moves')
          .insert(moveData);

        if (moveError) console.error('Erro ao salvar movimento:', moveError);

        // Broadcast do novo estado do jogo
        if (channelRef.current) {
          await channelRef.current.send({
            type: 'broadcast',
            event: 'game_update',
            payload: newGameState
          });
        }

      } catch (err) {
        console.error('Erro ao realizar movimento:', err);
        toast.error('Erro ao realizar movimento');
        await fetchGame();
      }
    } catch (err) {
      console.error('Erro ao realizar movimento:', err);
      toast.error('Erro ao realizar movimento');
      await fetchGame();
    }
  };

  const isCurrentPlayerPiece = (row: number, col: number): boolean => {
    if (!game) return false;
    
    const piece = game.board[row][col];
    
    // Se for um convidado, verificar pelo nome do jogador
    if (!session?.user) {
      const guestName = localStorage.getItem('guestName');
      const isPlayer1Guest = game.player1_name === guestName;
      const isPlayer2Guest = game.player2_name === guestName;
      
      const isPlayer1Piece = (piece === 1 || piece === 3) && isPlayer1Guest;
      const isPlayer2Piece = (piece === 2 || piece === 4) && isPlayer2Guest;
      
      return isPlayer1Piece || isPlayer2Piece;
    }
    
    // Se for usuário logado, verificar pelo ID
    const isPlayer1Piece = (piece === 1 || piece === 3) && game.player1_id === session.user.id;
    const isPlayer2Piece = (piece === 2 || piece === 4) && game.player2_id === session.user.id;
    
    return isPlayer1Piece || isPlayer2Piece;
  };

  const cleanupPlayerPresence = useCallback(async (isPageRefresh: boolean = false) => {
    if (!session?.user || !game) return;

    try {
      // Se for apenas um refresh ou navegação interna, salvar o estado no localStorage
      if (isPageRefresh || window.location.pathname.includes('/rooms/')) {
        localStorage.setItem(`game_presence_${game.id}`, JSON.stringify({
          userId: session.user.id,
          gameId: game.id,
          timestamp: new Date().getTime()
        }));
        return;
      }

      // Verificar se o jogador está realmente saindo do jogo
      const isLeavingGame = !window.location.pathname.includes('/rooms/');
      if (!isLeavingGame) return;

      // Se não for refresh, enviar evento antes de atualizar o banco
      const channel = supabase.channel(`game_room_${game.id}`);
      try {
        console.log('Enviando evento player_left...');
        const { data: profile } = await supabase
          .from('profiles')
          .select(`
            display_name,
            equipped_skin_id,
            avatar_skins (
              image_url
            )
          `)
          .eq('id', session.user.id)
          .single();

        // Enviar mensagem do sistema quando o jogador sair
        if (!isPageRefresh) {
          await sendSystemMessage(
            game.id,
            `👋 ${profile?.display_name || session.user.email} saiu`,
            'player_left'
          );
        }

        await channel.subscribe();
        await channel.send({
          type: 'broadcast',
          event: 'player_left',
          payload: {
            playerId: session.user.id,
            playerName: profile?.display_name || session.user.email,
            skinUrl: profile?.equipped_skin_id ? profile?.avatar_skins?.[0]?.image_url : null
          }
        });
      } catch (err) {
        console.error('Erro ao enviar evento player_left:', maskSensitiveInfo(err));
      }

      // Chamar a função leave_game para sair do jogo com segurança
      const { error } = await supabase.rpc('leave_game', {
        p_room_id: roomId
      });

      if (error) throw error;
      
      // Limpar o localStorage
      localStorage.removeItem(`game_presence_${game.id}`);

      // Desinscrever do canal após enviar o evento
      channel.unsubscribe();
    } catch (err) {
      console.error('Erro ao limpar presença:', maskSensitiveInfo(err));
    }
  }, [session?.user, game, roomId, supabase]);

  const hasTwoPlayers = () => {
    if (!game) return false;
    
    // Verificar jogador 1 (considerando convidados)
    const hasPlayer1 = Boolean(
      game.player1_id || 
      (game.player1_name && game.player1_name !== 'null' && game.player1_name.trim() !== '')
    );
    
    // Verificar jogador 2 (considerando convidados)
    const hasPlayer2 = Boolean(
      game.player2_id || 
      (game.player2_name && game.player2_name !== 'null' && game.player2_name.trim() !== '')
    );
    
    return hasPlayer1 && hasPlayer2;
  };

  return {
    game,
    loading,
    error,
    joinGame,
    makeMove,
    isPlayer1: game?.player1_id === session?.user?.id || (game?.player1_name === guestName && !session?.user),
    isPlayer2: game?.player2_id === session?.user?.id || (game?.player2_name === guestName && !session?.user),
    canPlay: game?.winner_id === null && hasTwoPlayers(),
    refetch: fetchGame,
    isCurrentPlayerPiece,
    cleanupPlayerPresence,
    canJoinGame: !!(
      game && 
      // Pode entrar se não estiver no jogo
      (
        (!session?.user && guestName && game.player1_name !== guestName && game.player2_name !== guestName) ||
        (session?.user && game.player1_id !== session.user.id && game.player2_id !== session.user.id)
      ) && 
      // E uma das posições está vazia
      !hasTwoPlayers()
    )
  };
} 